import React from 'react'
import { graphql } from 'gatsby'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Hero from '../../components/heroSubpage'

import { config } from '@fortawesome/fontawesome-svg-core'
config.autoAddCss = false /* eslint-disable import/first */

const About = ({ data }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <div id="sub-page">
        <Hero
          class="intro-65"
          image={post?.frontmatter?.image?.childImageSharp?.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          type="root"
        />
      </div>

      <section className="bg-white">
        <MDBContainer>
          <MDBRow>
            <MDBCol>
              <div
                className="font-w-400 text-xs-extra-large divlink text-opening-para"
                dangerouslySetInnerHTML={{ __html: post.frontmatter.openingpara, }}
              />

              {post.html && (
                <div
                  className="richtext divlink richtext-image"
                  dangerouslySetInnerHTML={{ __html: post.html }}
                />
              )}
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
    </Layout>
  )
}
export default About

export function Head({ data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}   
      canonical={location.pathname} 
    />   
  )
}

export const query = graphql`
  query {    
    markdownRemark(frontmatter: { name: { eq: "about" } }) {
      frontmatter {
        meta {
          title
          description 
        }        
        title
        subtitle
        alttext
        openingpara
        image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
      }
      html
    }
  }
`